import { ContainerFluid } from 'components/Viewport';
import './index.scss';

export default () => (
    <ContainerFluid className="toolbar copyright">
        <p>Copyright &copy; 2022-2023 杭州长帆健康科技有限公司</p>
        <p>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012929" target="_blank">
                <img src="/images/public-security-bureau.png" />
                 <span>浙公网安备 33010602012929号</span>
            </a>
            |
            <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022036512号-1</a>
        </p>
    </ContainerFluid>
);
