import { Card, Col, Row } from 'antd';
import { Container } from 'components/Viewport';
import './index.scss';

const members = [{
    name: '邓远德',
    description: 'Founder，CEO',
}, {
    name: '金力恒',
    description: '产品VP',
}, {
    name: '张泽鹏',
    description: '技术VP',
}];

export default () => (
    <Container className="introduction members">
        <a name="members" />
        <Row className="title">
            <Col span={24}>
                <h1>团队介绍</h1>
                <h2>TEAM MEMBERS</h2>
            </Col>
        </Row>
        <Row gutter={[{ xs: 0, sm: 16 }, 16]}>
            {members.map(({ name, description }, index) => (
                <Col key={name} xs={24} md={8}>
                    <Card
                        hoverable
                        bordered
                        cover={<img src={`/images/members-0${index+1}.png`} alt={name} />}
                    >
                        <Card.Meta title={name} description={description} />
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
);
