import { createRoot } from 'react-dom/client';
import 'antd/dist/reset.css';
import './components/index.scss';
import Viewport from 'components/Viewport';
import Navigator from 'components/Navigator';
import Jumbotron from 'components/Jumbotron';
import Company from 'components/Company';
import Members from 'components/Members';
import Resources from 'components/Products';
import Contacts from 'components/Contacts';
import Copyright from 'components/Copyright';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <Viewport>
        <Navigator/>
        <Jumbotron/>
        <Company/>
        <Members/>
        <Resources/>
        <Contacts/>
        <Copyright/>
    </Viewport>,
);
