import { Col, Row } from 'antd';
import './index.scss';

export const Container = ({ className = '', backgroundColor = null, children }) => (
    <Row className={className} style={{ backgroundColor }}>
      <Col xs={0} sm={1} lg={2}></Col>
      <Col xs={24} sm={22} lg={20}>{children}</Col>
      <Col xs={0} sm={1} lg={2}></Col>
    </Row>
);

export const ContainerFluid = ({ className = '', children }) => (
    <Row className={className}>
      <Col span={24}>{children}</Col>
    </Row>
);

export default ({ children }) => (
    <div className="viewport">{children}</div>
);
