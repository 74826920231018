import { Card, Col, Row } from 'antd';
import Icon from 'components/Icon';
import { Container } from 'components/Viewport';
import './index.scss';

const features = [{
    title: '聚焦代谢',
    description: '糖脂切入；10+疾病',
}, {
    title: 'AI驱动',
    description: '数字疗法赋能；商业可复制'
}, {
    title: '疗效核心',
    description: '病因溯源；签约管理',
}, {
    title: '渠道下沉',
    description: '基于本地需求；赋能基层医疗',
}];

export default () => (
    <Container className="introduction company">
        <a name="company" />
        <Row className="title">
            <Col span={24}>
                <h1>公司介绍</h1>
                <h2>COMPANY</h2>
            </Col>
        </Row>
        <Row gutter={[{ xs: 0, sm: 16 }, 16]}>
            {features.map(({ title, description }, index) => (
                <Col key={title} xs={24} sm={12} xl={6}>
                    <Card
                        hoverable
                        bordered
                        cover={<Icon src={`/images/features-0${index+1}.png`} />}
                    >
                        <Card.Meta title={title} description={description} />
                    </Card>
                </Col>
            ))}
        </Row>
        <Row className="description">
            <Col span={24}>近3000例入组，停药率91.7%，减药率99.8%！</Col>
        </Row>
    </Container>
);
