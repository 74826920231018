import { Carousel } from 'antd';
import { ContainerFluid } from 'components/Viewport';
import './index.scss';

const Picture = ({ src }) => (
    <div className="picture" style={{
        backgroundImage: `url("${src}")`
    }} />
);

export default () => (
    <ContainerFluid className="jumbotron">
        <Carousel autoplay>
            <Picture src="/images/banners-01.jpg" />
            <Picture src="/images/banners-02.jpg" />
        </Carousel>
        <div className="slogan">
            <h1>长帆科技让代谢更健康</h1>
            <h2>全球领先的一站式代谢病数字科技平台</h2>
        </div>
    </ContainerFluid>
);
