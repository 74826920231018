import { Col, List, Popover, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Container } from 'components/Viewport';
import './index.scss';

export default () => (
    <Container className="toolbar navigator">
      <Row>
          <Col className="brand" xs={22} sm={4} md={4} xl={3} xxl={2}>长帆健康</Col>
          <Col className="menu" xs={0} sm={19} md={20} xl={21} xxl={22}>
              <ul>
                  <li>
                      <a href="#company">公司介绍</a>
                  </li>
                  <li>
                      <a href="#members">团队介绍</a>
                  </li>
                  <li>
                      <a href="#products">产品介绍</a>
                  </li>
                  <li>
                      <a href="#contacts">联系我们</a>
                  </li>
              </ul>
          </Col>
          <Col className="pop-menu" xs={2} sm={0}>
              <Popover placement="bottomRight" trigger="click" content={
                  <List>
                      <List.Item>
                          <a href="#company">公司介绍</a>
                      </List.Item>
                      <List.Item>
                          <a href="#members">团队介绍</a>
                      </List.Item>
                      <List.Item>
                          <a href="#products">产品介绍</a>
                      </List.Item>
                      <List.Item>
                          <a href="#contacts">联系我们</a>
                      </List.Item>
                  </List>
              }>
                  <MenuOutlined style={{ color: 'white' }} />
              </Popover>
          </Col>
      </Row>
    </Container>
);
