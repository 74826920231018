import { Card, Col, Row } from 'antd';
import Icon from 'components/Icon';
import { Container } from 'components/Viewport';
import './index.scss';

const products = [{
    title: '专项检查',
    description: '全面筛查并发症',
}, {
    title: '病因溯源',
    description: 'MDT门诊专家评估',
}, {
    title: '个体化目标',
    description: '一人一策，逆转目标',
}, {
    title: 'AI治疗方案',
    description: '调整用药，风险预警',
}, {
    title: '复查评估',
    description: '复查检查，智能解读',
}, {
    title: '量化效果',
    description: '动态评估，动态调整',
}];

export default () => (
    <Container className="introduction products">
        <a name="products" />
        <Row className="title">
            <Col span={24}>
                <h1>产品介绍</h1>
                <h2>PRODUCTS</h2>
            </Col>
        </Row>
        <Row gutter={[{ xs: 0, sm: 16 }, 16]}>
            {products.map(({ title, description }, index) => (
                <Col key={title} xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Card
                        hoverable
                        bordered
                        cover={<Icon src={`/images/products-0${index+1}.png`} />}
                    >
                        <Card.Meta title={title} description={description} />
                    </Card>
                </Col>
            ))}
        </Row>
        <Row className="more">
            <Col span={24}>
                <a>了解更多</a>
            </Col>
        </Row>
    </Container>
);
