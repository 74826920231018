import { Row, Col } from 'antd';
import { HomeFilled, MailFilled, PhoneFilled, WechatFilled } from '@ant-design/icons';
import { Container } from 'components/Viewport';
import './index.scss';

export default () => (
    <Container className="toolbar contacts">
        <a name="contacts" />
        <Row gutter={[{ xs: 0, sm: 16 }, 16]}>
            <Col xs={24} sm={12} md={6}>
                <PhoneFilled />
                电话：400-888-8888
            </Col>
            <Col xs={24} sm={12} md={6}>
                <WechatFilled />
                微信：xxxxxxx
            </Col>
            <Col xs={24} sm={12} md={6}>
                <MailFilled />
                邮箱：xxxx@sailhealth.cn
            </Col>
            <Col xs={24} sm={12} md={6}>
                <HomeFilled />
                地址：浙江省杭州市蒋村街道紫霞街71号
            </Col>
        </Row>
    </Container>
);
